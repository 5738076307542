import React from 'react';
import { UserRecord } from '../../../services/fetchUser';

interface UserProfileProps {
    selectedUser: UserRecord;
}

const UserProfile: React.FC<UserProfileProps> = ({ selectedUser }) => {
    return (
        <form className='container py-5'>
            <div className='mb-3'>
                <label htmlFor='displayName' className='form-label'>Full Name:</label>
                <input type='text' id='displayName' value={selectedUser.displayName || `${selectedUser.firstName} ${selectedUser.lastName}`} className='form-control' disabled />
            </div>
            <div className='mb-3'>
                <label htmlFor='email' className='form-label'>Email:</label>
                <input type='email' id='email' value={(!selectedUser.autoGeneratedEmail && selectedUser.email) || ''} className='form-control' disabled />
            </div>
            <div className='mb-3'>
                <label htmlFor='linkedId' className='form-label'>Patient ID:</label>
                <input type='text' id='linkedId' value={selectedUser.linkedIds[0]} className='form-control' disabled />
            </div>
            {/* Add more input fields for other properties of selectedUser */}
        </form>
    );
};

export default UserProfile;