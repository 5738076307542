import { authPingooClinic } from '../firebaseConfig';
import apiFetch from './apiFetch';

export interface UserRecord {
    uid:string;
    firstName:string;
    lastName:string;
    displayName:string;
    email:string;
    autoGeneratedEmail:boolean;
    linkedIds:string[],
    parentIds:string[],
}
export async function fetchChildUser(childUserId:string,childUserIdAsPatientId = false ): Promise<UserRecord> {
    const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
    const response = await apiFetch(`${apiUrl}/webClient/childUsers/${childUserId}${childUserIdAsPatientId ? '?childUserIdAsPatientId=true' : ''}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }, authPingooClinic);
    if (response?.ok) {
      const jsonData = await response?.json();
      return jsonData;
    } else {
      console.error("Request failed with status: " + response?.status);
      console.error('Error signing into Project Pingoo-clinic with custom token:', response);
      return {} as UserRecord;
    }
  }