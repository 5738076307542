import { Image } from 'react-bootstrap';

type GlucoseOverviewProps = {
  };
  
  const GlucoseOverview: React.FC<GlucoseOverviewProps> = () => {
    return (
      <div>
      <h6> Welcome To Ambient AI</h6>
        <Image thumbnail fluid={true}  src="/dashboard_top.png" />
        {/* Render the chart and stats here using the passed props */}
      </div>
    );
  };
  
  export default GlucoseOverview;
  