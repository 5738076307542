import React from 'react';

interface RecommendationsProps {
  // Define props here if needed, like recommendation text
}

const Recommendations: React.FC<RecommendationsProps> = (props) => {
  return (
    <div>
      <h6>How does Ambient AI Work?</h6>
      <div className="border rounded-2 py-3">
        <ul>
          <li>Go to the Session Tab from top menu</li>
          <li>Select a Patient from left column</li>
          <li>When ready, click on Start Recording</li>
          <li>Wait until the row updates</li>
          <li>Click on the row for the session</li>
        </ul>
      </div>
    </div>
  );
};

export default Recommendations;
