import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserProfile from './UserProfile/UserProfile';
import UserSessions from '../UserSessions/UsersSessions';
import UserIntakeForm from './UserIntakeForm/UserIntakeForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';
import { UserRecord, fetchChildUser } from '../../services/fetchUser';
import UserRecordings, { RecordingsViewMode } from '../UserRecordings/UserRecordings';
import GoToPatientsList from '../GoToPatientsList/GoTpPatientsList';

type UserActionProps = { patient?: UserRecord ,action?: string};

type RouteParams = {
    action: string;
    userId: string;
};

const UserAction: React.FC<UserActionProps> = ({ patient, action }) => {
    const [selectedUser, setSelectedUser] = useState<UserRecord | null>(patient || null);
    const [loading, setLoading] = useState(false);
    const isFetchingRef = useRef(false);

    const navigate = useNavigate();
    const params = useParams<RouteParams>();
    const selectedUserId = patient?.uid || params.userId || '';
    action = action || params.action || 'newRecording';

    const fetchUser = useCallback(async () => {
        if (selectedUserId && !patient && !selectedUser && !isFetchingRef.current) {
            isFetchingRef.current = true;
            setLoading(true);
            try {
                const user = await fetchChildUser(selectedUserId);
                setSelectedUser(user);
            } catch (error) {
                console.error('Error fetching user:', error);
            } finally {
                setLoading(false);
                isFetchingRef.current = false;
            }
        }
    }, [selectedUserId, patient, selectedUser]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleSelect = useCallback((selectedTab: string | null) => {
        if (selectedUser) {
            navigate(`/user/${selectedUser.uid}/${selectedTab}`);
        }
    }, [navigate, selectedUser]);

    const getUserNameAndId = useCallback((user: UserRecord): string => {
        const patientIdSection = user.linkedIds[0] ? `Patient ID: ${user.linkedIds[0]}` : '';
        const patientNameSection = ((user.firstName || user.lastName) && `${user.firstName} ${user.lastName}`) || user.displayName;
        return patientNameSection + (patientNameSection && patientIdSection && ' | ') + patientIdSection;
    }, []);

    const memoizedTabs = useMemo(() => {
        if (!selectedUser) return null;

        return (
            <Tabs defaultActiveKey={action} activeKey={params.action || action} onSelect={handleSelect} id="user-action-tabs">
                <Tab eventKey="profile" title="Profile">
                    {action === 'profile' && <UserProfile selectedUser={selectedUser} />}
                </Tab>
                <Tab eventKey="newRecording" title="New Session">
                    {action === 'newRecording' && <UserSessions selectedUser={selectedUser} />}
                </Tab>
                <Tab eventKey="pastSessions" title="Past Sessions">
                    {action === 'pastSessions' && (
                        <div className="mt-3 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 180px)' }}>
                            <UserRecordings viewMode={RecordingsViewMode.Past} selectedUser={selectedUser} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="intakeForm" title="Intake Form">
                    {(action === 'intakeForm') && (
                        <UserIntakeForm key={selectedUser.uid} selectedUser={selectedUser} />
                    )}
                </Tab>
            </Tabs>
        );
    }, [action, params.action, handleSelect, selectedUser]);

    if (loading) {
        return (
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '75vh' }}>
                <FontAwesomeIcon icon={faSpinner} size={'2x'} spin />
            </div>
        );
    }

    if (!selectedUser) {
        return null;
    }

    return (
        <div className='w-100 p-4'>
            <span className='d-flex flex-sm-row'>
                <GoToPatientsList />
                <span className='ms-auto'>{selectedUser ? getUserNameAndId(selectedUser) : ''}</span>
            </span>
            <div className='my-3'></div>
            {memoizedTabs}
        </div>
    );
};

export default React.memo(UserAction);