import { useState, useEffect, useCallback, useRef, memo } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import Conversation from '../../pages/conversation/Conversation';
import Login from '../../pages/login/Login';
import SignUp from '../../pages/signUp/signUp';
import InviteUser from '../InviteUser/InviteUser';
import Landing from '../Landing/Landing';
import UserAction from '../UserAction/UserAction';
import PatientWrapper from '../PatientWrapper';
import loginPingooClinic from '../../services/loginPingooclinic';

const { authPingooClinic, authPingoo } = require('../../firebaseConfig');

const AuthHandler: React.FC = () => {
  const [authState, setAuthState] = useState({ isLoggedIn: false, isLoading: true });
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticatingRef = useRef(false);

  const handleAuth = useCallback(async (loginToken: string | null) => {
    if (isAuthenticatingRef.current) {
      console.log('Authentication already in progress, skipping...');
      return;
    }

    isAuthenticatingRef.current = true;

    try {
      if (loginToken) {
        await signInWithCustomToken(authPingoo, loginToken);
        navigate(location.pathname, { replace: true });
        console.log('User signed into Project Pingoo');
        const user = await loginPingooClinic();
        setAuthState({ isLoggedIn: !!user, isLoading: false });
      } else {
        const user = await new Promise<any>((resolve) => {
          const unsubscribe = authPingooClinic.onAuthStateChanged((user: any) => {
            unsubscribe();
            resolve(user);
          });
        });
        setAuthState({ isLoggedIn: !!user, isLoading: false });
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setAuthState({ isLoggedIn: false, isLoading: false });
    } finally {
      isAuthenticatingRef.current = false;
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const loginToken = params.get('loginToken');
    handleAuth(loginToken);
  }, [location.search, handleAuth]);

  useEffect(() => {
    const debugQueryParam = new URLSearchParams(location.search).get('debug');
    if (debugQueryParam === 'true') {
      sessionStorage.setItem('debugMode', 'true');
    } else if (debugQueryParam === 'false') {
      sessionStorage.removeItem('debugMode');
    }
  }, [location.search]);

  if (authState.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={authState.isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />} />
      <Route path="/signUp" element={authState.isLoggedIn ? <Navigate to="/invite" replace /> : <SignUp />} />
      <Route path="/" element={authState.isLoggedIn ? <Conversation /> : <Navigate to="/login" replace />}>
        <Route path="invite" element={<InviteUser />} />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path=":landingRoute" element={<Landing />} />
        <Route path="user/:userId/" element={<Navigate to="newRecording" replace />} />
        <Route path="user/:userId/:action" element={<UserAction />} />
        <Route path="patient/:patientId" element={<PatientWrapper />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default memo(AuthHandler);