import { authPingooClinic } from '../firebaseConfig';
import apiFetch from './apiFetch';

export interface AddUserRecord {
    firstName:string;
    lastName:string;
    email:string;
    linkedId?:string
}
export async function addChildUser(childUser:AddUserRecord): Promise<AddUserRecord> {
    const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
    const response = await apiFetch(`${apiUrl}/webClient/childUsers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(childUser)
    }, authPingooClinic);
    const jsonData = await response?.json();
    if (response?.ok) {
      return jsonData;
    } else {
      console.error("Request failed with status: " + response?.status);
      throw new Error(jsonData.error);
    }
  }