import { useEffect, useMemo, useState } from 'react';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import SoapNote, { ISoapNote } from './SoapNote/SoapNote';
import StatusBadge, { FileProcessingStatus } from '../StatusBadge/StatusBadge';
import fetchWithBearerToken from '../../services/apiFetch';
import { UserRecord } from '../../services/fetchUser';
import useFiles from '../useFiles/useFiles';
import DialogueTranscript from './DialogueTranscript/DialogueTranscript';
import PatientSummary from './PatientSummary/PatientSummary';


export interface DialogueEntry {
    speaker: string;
    content: string;
}

export interface IPatientSummary {
    text: string;
    nextSteps: string[];
    edits?: { text: string, editedAt: number, approved: boolean }[]; // array of modified edits, this usually has only one item and is the approved one
}

export interface RecordingFilter {
    overallStatusIn: FileProcessingStatus[];
    overallStatusNotIn: FileProcessingStatus[];
    startAfter: string; // the document ID which the query will start from
    limit: number
}

export enum RecordingsViewMode {
    Pending = 'pending',
    Past = 'past',
    Last = 'last'
}

interface UserRecordingsProps {
    className?: string;
    selectedUser: UserRecord;
    currentFileId?: string;
    onRecordingDeleted?: (deletedFileId: string) => void;
    onRecordingsCountUpdate?: (recordingCount: number) => void;
    viewMode: RecordingsViewMode;
    showLoader?: boolean
}

const UserRecordings = ({ className = '', selectedUser, currentFileId, viewMode, showLoader = true, onRecordingsCountUpdate, onRecordingDeleted }: UserRecordingsProps) => {
    const { authPingooClinic } = require('../../firebaseConfig');
    const filter: Partial<RecordingFilter> = useMemo(() => {
        let recordingFilter: Partial<RecordingFilter>;
        switch (viewMode) {
            case RecordingsViewMode.Last:
                recordingFilter = { limit: 1, overallStatusNotIn: [FileProcessingStatus.Started] };
                break;
            case RecordingsViewMode.Past:
                recordingFilter = { overallStatusNotIn: [FileProcessingStatus.Started] };
                break;
            case RecordingsViewMode.Pending:
                recordingFilter = { overallStatusIn: [FileProcessingStatus.Started] };
                break;
        }
        return recordingFilter;
    }, [viewMode]);

    const userId = authPingooClinic.currentUser?.uid;
    const { files, isLoading } = useFiles(userId, selectedUser.uid, filter);
    const [overallStatusComplete, setOverallStatusComplete] = useState<boolean>(false);

    const [analyzing, setAnalyzing] = useState(false);
    const [isDeleting, setIsDeleting] = useState('');


    const [activeKey, setActiveKey] = useState<string | null>(null);
    const [fileCount, setFileCount] = useState<number>(0);
    const [defaultActiveTab, setDefaultActiveTab] = useState<string>('annotation');



    const debugMode = sessionStorage.getItem('debugMode') === 'true';


    useEffect(() => {
        console.info('Current file id:', currentFileId);

        setFileCount(prevCount => {
            if (viewMode === RecordingsViewMode.Pending) {
                if (prevCount > 0 && files.length === 0) {
                    setAnalyzing(false);
                }
                else {
                    setAnalyzing(files.length === 0 && !!currentFileId);
                }
            }
            if (files.length && currentFileId) {
                setActiveKey(currentFileId);
            }
            return files.length
        })
    }, [currentFileId, files, viewMode]);


    useEffect(() => {
        setOverallStatusComplete(!analyzing && files.length > 0 && files[0].overallStatus !== FileProcessingStatus.Started);
    }, [analyzing, currentFileId, files])

    useEffect(() => {
        if (!files || files.length === 0) return;
        const eventToDefaultActiveTab: { [key: string]: string } = {
            'annotation': 'annotation',
            'patientSummary': 'patientSummary',
            'transcribeToDialogue': 'dialogueTranscript',
        };
        const lastStartedEvent = files[0]?.fileProcessingTracking?.findLast((item: { event: string; status: string; }) => item.status === 'started')
        setDefaultActiveTab(eventToDefaultActiveTab[lastStartedEvent?.event] || 'annotation');
    }, [files])

    useEffect(() => {
        onRecordingsCountUpdate && onRecordingsCountUpdate(fileCount)
    }, [fileCount, onRecordingsCountUpdate])

    const downloadFile = (fileContent: any, fileName: string) => {
        const blob = new Blob([JSON.stringify(fileContent, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const deleteRecording = async (recordingId: string) => {
        setIsDeleting(recordingId);
        const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
        await fetchWithBearerToken(`${apiUrl}/webClient/childUsers/${selectedUser.uid}/recordings/${recordingId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, authPingooClinic)
        setIsDeleting('');
        if (onRecordingDeleted) {
            onRecordingDeleted(recordingId); // Call the callback function with the deleted file ID
        }
    }




    return (
        <div className={className}>
            {(showLoader && isLoading) && <div className="text-center"><FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon></div>}
            {!isLoading && <>
                {analyzing && <div className='text-center mb-3'><strong><FontAwesomeIcon className='me-2' icon={faSpinner} spin={true}></FontAwesomeIcon>Analyzing</strong></div>}
                {!analyzing && (
                    <>
                        {viewMode === RecordingsViewMode.Past && !files.length && <div className='text-center'>No sessions found</div>}
                        {overallStatusComplete && (<div className="mb-2"><strong>Most recent session:</strong></div> || null)}
                        <Accordion activeKey={activeKey} onSelect={(e) => setActiveKey(e as string)}>
                            {files.map((file, index) => {
                                const fileProcessingTracking = file.fileProcessingTracking?.sort((a: { timestamp: number; }, b: { timestamp: number; }) => a.timestamp - b.timestamp);
                                if (!fileProcessingTracking) return null;
                                const annotation: ISoapNote = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'annotation' && item.status === 'success')?.content;
                                const patientSummary: IPatientSummary = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'patientSummary' && item.status === 'success')?.content;
                                const patientSummaryFailure: IPatientSummary = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'patientSummary' && item.status === 'failure')?.content;
                                const patientSummaryStatus: string = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'patientSummary')?.status;

                                const transcribeAudio: string = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'transcribeAudio' && item.status === 'success')?.content;
                                const transcribeToDialogue: DialogueEntry[] = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'transcribeToDialogue' && item.status === 'success')?.content;
                                const transcribeToDialogueFailure: DialogueEntry[] = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'transcribeToDialogue' && item.status === 'failure')?.content;
                                const transcribeToDialogueStatus: string = fileProcessingTracking.find((item: { event: string; status: string; }) => item.event === 'transcribeToDialogue')?.status;

                                return (
                                    <Accordion.Item eventKey={file.id} key={file.id}>
                                        <Accordion.Header>
                                            <section className="d-flex justify-content-between align-items-center w-100">
                                                <span className="w-100">
                                                    <div className='d-flex'>
                                                        {debugMode && (<FontAwesomeIcon
                                                            className="me-3 text-secondary"
                                                            icon={faDownload}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                const mappedData = {
                                                                    "SOAP NOTES": annotation,
                                                                    "Visit Summary for patient": patientSummary,
                                                                    "Recording Transcript": transcribeToDialogue || transcribeAudio
                                                                };
                                                                downloadFile(mappedData, `Recording_${new Date(Number(file.id)).toLocaleString()}`);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        /> || null)}
                                                        <span>Session date: {new Date(Number(file.id)).toLocaleString()}</span>
                                                        {debugMode && (<FontAwesomeIcon className='ms-auto me-2 text-danger opacity-75' onClick={(e) => {
                                                            e.stopPropagation();
                                                            isDeleting !== file.id && deleteRecording(file.id);
                                                        }} spin={isDeleting === file.id} icon={isDeleting === file.id ? faSpinner : faTrash}></FontAwesomeIcon> || null)}
                                                    </div>
                                                </span>
                                            </section>
                                        </Accordion.Header>
                                        <Accordion.Body >
                                            <div className='mt-2'>
                                                <StatusBadge fileProcessingTracking={fileProcessingTracking} mode={'simple'} />
                                            </div>
                                            {<Tabs key={defaultActiveTab} defaultActiveKey={defaultActiveTab} id="file-info-tabs" className="mb-3">
                                                {debugMode && transcribeAudio &&
                                                    <Tab eventKey="transcript" title="Recording Transcript">
                                                        <ul className='list-unstyled'>
                                                            {transcribeAudio.split('\n').map((line, index) => (
                                                                <li key={index}>{line}</li>
                                                            ))}
                                                        </ul>
                                                    </Tab>}

                                                {annotation?.subjective && annotation?.objective.vitalSigns &&
                                                    <Tab eventKey="annotation" title="SOAP Note">
                                                        <SoapNote selectedUser={selectedUser} note={annotation} fileId={file.id} />
                                                    </Tab>
                                                }
                                                {transcribeAudio && annotation &&
                                                    <Tab eventKey="patientSummary" title="Visit Summary for patient">
                                                        <PatientSummary
                                                            patientSummary={patientSummary}
                                                            patientSummaryStatus={patientSummaryStatus}
                                                            patientSummaryFailure={patientSummaryFailure}
                                                            selectedUser={selectedUser}
                                                            fileId={file.id}
                                                        />
                                                    </Tab>
                                                }
                                                {transcribeAudio && annotation &&
                                                    <Tab eventKey="dialogueTranscript" title="Transcript">
                                                        <DialogueTranscript
                                                            transcribeToDialogue={transcribeToDialogue}
                                                            transcribeToDialogueStatus={transcribeToDialogueStatus}
                                                            transcribeToDialogueFailure={transcribeToDialogueFailure}
                                                            selectedUser={selectedUser}
                                                            fileId={file.id}
                                                        />
                                                    </Tab>
                                                }


                                                {debugMode && (
                                                    <Tab eventKey="processProgress" title="Process progress">
                                                        <StatusBadge fileProcessingTracking={fileProcessingTracking} mode={'detailed'} />
                                                    </Tab>
                                                )}
                                            </Tabs>}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                        </Accordion>
                        {files.length?<div className="d-block d-sm-none" style={{marginBottom:"150px"}}>
                        </div>:null}
                    </>)}
            </>}
        </div>
    );
};

export default UserRecordings;