import { signInWithCustomToken } from 'firebase/auth';
import { authPingoo, authPingooClinic } from '../firebaseConfig';
import apiFetch from './apiFetch';

const loginPingooClinic = async () => {
    const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
    const response = await apiFetch(`${apiUrl}/createCustomToken`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }, authPingoo);
    if (response?.ok) {
      const jsonData = await response.json();
      // sign into Project Pingoo-clinic with custom token
      const userCredential = await signInWithCustomToken(authPingooClinic, jsonData.customToken);
      console.log('User signed into Project Pingoo-clinic', userCredential.user);
      return userCredential.user;

    } else {
      console.error("Request failed with status: " + response?.status);
      console.error('Error signing into Project Pingoo-clinic with custom token:', response);
      return null;
    }
  }
    export default loginPingooClinic;