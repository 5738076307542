import React from 'react';
import UserRecordingDashboard from '../UserRecordingDashboard/UserRecordingDashboard';

import { UserRecord } from '../../services/fetchUser';


interface UserSessionsProps {
    selectedUser: UserRecord
}


const UserSessions: React.FC<UserSessionsProps> = ({selectedUser}) => {

    return (
        <UserRecordingDashboard selectedUser={selectedUser} />
    );
};

export default UserSessions;