import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { IPatientSummary } from '../UserRecordings';
import { Button, Modal } from 'react-bootstrap';
import CopyButton from '../../CopyButton/CopyButton';
import { DocumentData } from 'firebase/firestore';
import { authPingooClinic } from '../../../firebaseConfig';
import fetchWithBearerToken from '../../../services/apiFetch';
import { UserRecord } from '../../../services/fetchUser';

interface PatientSummaryProps {
    patientSummary: IPatientSummary | null;
    patientSummaryStatus: string;
    patientSummaryFailure: any;
    selectedUser: UserRecord;
    fileId: string;
}

const convertPatientSummaryToText = (patientSummary: IPatientSummary): string => {
    let formattedText = `Summary:\n${patientSummary.text}\n\nNext Steps:\n`;
    formattedText += patientSummary.nextSteps.map(step => `- ${step}`).join('\n');
    return formattedText;
};

const PatientSummary: React.FC<PatientSummaryProps> = ({
    patientSummary,
    patientSummaryStatus,
    patientSummaryFailure,
    selectedUser,
    fileId,
}) => {
    const [isLoadingSummary, setIsLoadingSummary] = useState<{ [key: string]: boolean }>({});
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState('');
    const [isApproving, setIsApproving] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const generatePatientSummary = async (file: DocumentData) => {
        setIsLoadingSummary({ ...isLoadingSummary, [file.id]: true });
        try {
            const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
            const response = await fetchWithBearerToken(`${apiUrl}/webClient/childUsers/${selectedUser.uid}/recordings/${file.id}/triggerPatientSummary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, authPingooClinic);
            if (response?.ok) {
                const data = await response.json();
                console.log('Patient summary initiated:', data);
            } else {
                console.error('Error fetching patient summary:', response?.statusText);
            }
        } catch (error) {
            console.error('Error during patient summary generation:', error);
        }
    }

    const handleEdit = () => {
        setIsEditing(true);
        setEditedText(convertPatientSummaryToText(patientSummary!));
    }

    const handleCancel = () => {
        setIsEditing(false);
        setEditedText('');
    }

    const handleApproveClick = () => {
        setShowConfirmDialog(true);
    }

    const handleConfirmApprove = async () => {
        setShowConfirmDialog(false);
        setIsApproving(true);
        try {
            const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
            const response = await fetchWithBearerToken(`${apiUrl}/webClient/childUsers/${selectedUser.uid}/recordings/${fileId}/patientSummary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: editedText }),
            }, authPingooClinic);
            if (response?.ok) {
                const data = await response.json();
                console.log('Patient summary updated:', data);
                setIsEditing(false);
                // You might want to update the patientSummary state here with the new data
            } else {
                console.error('Error updating patient summary:', response?.statusText);
            }
        } catch (error) {
            console.error('Error during patient summary update:', error);
        }
        setIsApproving(false);
    }

    const handleCancelApprove = () => {
        setShowConfirmDialog(false);
    }

    const lastEdit = patientSummary?.edits && patientSummary.edits[patientSummary.edits.length - 1];
    const isApproved = lastEdit && lastEdit.approved;
    const approvedText = isApproved ? lastEdit.text : null;

    if (!patientSummary?.text) {
        return (
            <>
                {!(isLoadingSummary[fileId] || patientSummaryStatus === 'started') && (
                    <>
                        <span>Click on below button to generate summary of the visit for the patient. This may take a few minutes.</span>
                        <div className="d-flex mt-2 justify-content-center align-items-center h-100">
                            <Button className='text-white' variant='primary' onClick={() => generatePatientSummary({ id: fileId } as DocumentData)}>
                                Generate Summary
                            </Button>
                        </div>
                    </>
                )}
                {((isLoadingSummary[fileId] || patientSummaryStatus === 'started') && !patientSummaryFailure) && (
                    <div className="py-5 text-center">
                        <FontAwesomeIcon className="me-2" icon={faSpinner} spin={true} /> Generating summary... please wait....
                    </div>
                )}
            </>
        );
    }

    if (patientSummaryFailure) {
        return (
            <div className="py-5 text-center text-danger">
                <div>Error creating patient summary: </div>
                <FontAwesomeIcon icon={faWarning} /> <>{patientSummaryFailure}</>
            </div>
        );
    }

    return (
        <section id={`patient-summary-${fileId}`}>
            {isEditing ? (
                <>
                    <textarea
                        value={editedText}
                        onChange={(e) => setEditedText(e.target.value)}
                        rows={10}
                        className="form-control mb-3"
                    />
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className='text-white' onClick={handleApproveClick} disabled={isApproving}>
                            {isApproving ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Approve'}
                        </Button>
                        <Button variant="outline-danger" onClick={handleCancel} className="ms-2">
                            Cancel
                        </Button>
                    </div>

                    {/* Confirmation Dialog */}
                    <Modal show={showConfirmDialog} onHide={handleCancelApprove}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Approval</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to approve these changes? Once approved, they cannot be modified later.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary"  className='text-white' onClick={handleConfirmApprove}>
                                Yes, Approve
                            </Button>
                            <Button variant="outline-danger" onClick={handleCancelApprove}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : approvedText ? (
                <>
                    <textarea
                        value={approvedText}
                        readOnly
                        rows={10}
                        className="form-control mb-3"
                    />
                    <div className="d-flex justify-content-between align-items-center">
                        <CopyButton
                            text={approvedText}
                            label="Copy Visit Summary"
                            copyKey={`summary-${fileId}`}
                        />
                        <span className="text-success">
                            Approved on {new Date(lastEdit!.editedAt).toLocaleString()}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    {/* Formatted section*/}
                    <div className='mb-2'><strong>Summary: </strong></div>
                    <div className='mb-2'>{patientSummary.text}</div>
                    <div className='mb-2'><strong>Next steps:</strong></div>
                    <div className='mb-2'>
                        <ul>{patientSummary.nextSteps.map((step, ind) => <li key={ind}>{step}</li>)}</ul>
                    </div>
                    {/* End of Formatted section*/}
                    <div className="d-flex justify-content-end">
                        <Button variant="outline-primary" onClick={handleEdit}>
                            Edit draft
                        </Button>
                    </div>
                </>
            )}
        </section>
    );
};

export default PatientSummary;