import React, { useEffect, useRef } from 'react';
import fetchWithBearerToken from '../../../services/apiFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Container, ToggleButton } from 'react-bootstrap';
import { authPingooClinic } from '../../../firebaseConfig';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UserRecord } from '../../../services/fetchUser';

interface UserIntakeFormProps {
    selectedUser: UserRecord;
}

interface IntakeForm {
    txt: string;
    html: string;
}



const UserIntakeForm: React.FC<UserIntakeFormProps> = ({ selectedUser }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [showHtml, setShowHtml] = React.useState(false);
    const isFetchingRef = useRef(false);

    const [intakeForm, setIntakeForm] = React.useState<IntakeForm>({ txt: '', html: '' });

    const getIntakeForm = React.useCallback(async (selectedUser: string): Promise<IntakeForm> => {
        if (!selectedUser || isFetchingRef.current) {
            return { txt: '', html: '' };
        }
        isFetchingRef.current = true;
        setIsLoading(true);
        const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
        try{
        const response = await fetchWithBearerToken(`${apiUrl}/webClient/childUsers/${selectedUser}/intakeForm`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, authPingooClinic)
        setIsLoading(false);
        return response?.json();
        } finally {
            isFetchingRef.current = false;
        }
    }, []);

    useEffect(() => {
        getIntakeForm(selectedUser.uid)
            .then(form => setIntakeForm(form))
    }, [getIntakeForm, selectedUser]);

    return (
        isLoading ?
            <div className="d-flex justify-content-center align-items-center" style={{ height: '75vh' }}>
                <FontAwesomeIcon icon={faSpinner} spin />
            </div> :
            (!intakeForm.html && !intakeForm.txt &&
            <Container className='mt-3' >
                <section className="text-center">
                    No form found
                </section>
            </Container> )||
            <Container className='pt-3' >
                <ButtonGroup>
                    {intakeForm.txt && (<ToggleButton
                        type="checkbox"
                        variant="outline-dark"
                        size="sm"
                        checked={!showHtml}
                        value="txt"
                        onClick={() => setShowHtml(false)} id={''}                >
                        Text
                    </ToggleButton> || null)}
                    {intakeForm.html && (<ToggleButton
                        type="checkbox"
                        variant="outline-dark"
                        size="sm"
                        checked={showHtml}
                        value="html"
                        onClick={() => setShowHtml(true)} id={''}                >
                        Formatted
                    </ToggleButton> || null)}

                </ButtonGroup>
                <section className="mt-3">
                    {showHtml && intakeForm.html && <div className='border p-2 overflow-scroll' dangerouslySetInnerHTML={{ __html: intakeForm.html }}></div>}
                    {!showHtml && intakeForm.txt && <pre className='text-wrap'>{intakeForm.txt}</pre>}

                </section>

            </Container>
    );
};

export default UserIntakeForm;