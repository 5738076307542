import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { DialogueEntry } from '../UserRecordings';
import { Button } from 'react-bootstrap';
import CopyButton from '../../CopyButton/CopyButton';
import { useState } from 'react';
import fetchWithBearerToken from '../../../services/apiFetch';
import { authPingooClinic } from '../../../firebaseConfig';
import { UserRecord } from '../../../services/fetchUser';


interface DialogueTranscriptProps {
    transcribeToDialogue: DialogueEntry[] | null;
    transcribeToDialogueStatus: string;
    transcribeToDialogueFailure: any;
    selectedUser: UserRecord;
    fileId:string;
}

const DialogueTranscript: React.FC<DialogueTranscriptProps> = ({
    transcribeToDialogue,
    transcribeToDialogueStatus,
    transcribeToDialogueFailure,
    selectedUser,
    fileId,
}) => {
    const [isLoadingFormattedTranscript, setIsLoadingFormattedTranscript] = useState<{ [key: string]: boolean }>({});

    const generateFormattedTranscript = async (fileId: string) => {
        setIsLoadingFormattedTranscript({ ...isLoadingFormattedTranscript, [fileId]: true }); // Start loading
        try {
            const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
            const response = await fetchWithBearerToken(`${apiUrl}/webClient/childUsers/${selectedUser.uid}/recordings/${fileId}/formattedTranscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, authPingooClinic);
            if (response?.ok) {
                const data = await response.json();
                console.log('Transcription initiated:', data);
            } else {
                // Handle non-OK responses here, if needed
                console.error('Error fetching formatted transcript:', response?.statusText);
            }
        } catch (error) {
            console.error('Error during formatted transcript generation:', error);
        }
    }

    if (!transcribeToDialogue) {
        return (
            <>
                {!(isLoadingFormattedTranscript[fileId] || transcribeToDialogueStatus === 'started') && (
                    <>
                        <span>Click on below button to generate transcript of the visit. This may take a few minutes.</span>
                        <div className="d-flex mt-2 justify-content-center align-items-center h-100">
                            <Button className='text-white' variant='primary' onClick={()=>generateFormattedTranscript(fileId)}>
                                Generate transcript
                            </Button>
                        </div>
                    </>
                )}
                {((isLoadingFormattedTranscript[fileId] || transcribeToDialogueStatus === 'started') && !transcribeToDialogueFailure) && (
                    <div className="py-5 text-center">
                        <FontAwesomeIcon className="me-2" icon={faSpinner} spin={true} /> Generating transcript... please wait....
                    </div>
                )}
            </>
        );
    }

    if (transcribeToDialogueFailure) {
        return (
            <>
                <span>Error creating transcript: </span>
                <div className="py-5 text-center text-danger">
                    <FontAwesomeIcon icon={faWarning} /> <>{transcribeToDialogueFailure}</>
                </div>
            </>
        );
    }

    return (
        <>
            <ul className='list-unstyled'>
                {transcribeToDialogue.map((dt, dtIndex) => (
                    <li key={dtIndex}>
                        <><strong>{dt.speaker}:</strong> {dt.content}<br /></>
                    </li>
                ))}
            </ul>
            <CopyButton
                text={transcribeToDialogue.map(dt => `${dt.speaker}: ${dt.content}`).join('\n')}
                label="Copy Transcript"
                copyKey={`transcript-${fileId}`}
            />
        </>
    );
};

export default DialogueTranscript;