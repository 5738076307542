import { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import { Outlet } from 'react-router-dom';

const Conversation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check if the headerRef is currently being referenced and if so, update the headerHeight state
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      setHeaderHeight(height);
    }
  }, [headerRef.current]); // Dependency array includes headerRef.current to re-run effect if it changes

  return (
    <>
      {/* Pass ref to Header to capture its DOM node */}
      <Header ref={headerRef} toggleMenu={toggleMenu} />
      {/* Apply dynamic top margin based on header height */}
<div className="d-flex flex-row overflow-auto position-fixed w-100" style={{ height: `calc(98vh - ${headerHeight}px)` }}>
  <Outlet />
</div>
    </>
  );
};

export default Conversation;