// note an option token can get passed to this function to overwrite the existing idToken token for this user

import { Auth } from 'firebase/auth';

// the reason is to support login with custom token, where the bearer token will get passed to the url
const fetchWithBearerToken = async (
  url: string,
  options = {} as any,
  authApp: Auth
) => {
  const currentUser = authApp.currentUser;
  try {
    if (currentUser) {
      const _token = await currentUser?.getIdToken();
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${_token}`,
      };
      const response = await fetch(`${url}`, options);
      if (!response.ok) {
        throw new Error('Request failed');
      }
      return response;
    }
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export default fetchWithBearerToken;
