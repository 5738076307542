import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Dashboard from '../Dashboard/Dashboard';
import UsersList from '../UsersList/UsersList';
import { useNavigate, useParams } from 'react-router-dom';

type LandingProps = {
};
type RouteParams = {
    landingRoute?: string
};

const Landing: React.FC<LandingProps> = () => {
    const navigate = useNavigate();

    const params = useParams<RouteParams>();
    const landingRouteParam = params.landingRoute || '';

    const handleSelect = (selectedTab: string | null) => {
        navigate(`/${selectedTab}`);
    };
    return (
        <div className='w-100'>
            <Tabs defaultActiveKey={landingRouteParam || 'users'} activeKey={params.landingRoute || landingRouteParam} onSelect={handleSelect} id="user-tabs">
                <Tab eventKey="dashboard" title="Dashboard">
                    <Dashboard  />
                </Tab>
                <Tab eventKey="users" title="My Patients">
                    <UsersList onInviteUser={()=>{}}/>
                </Tab>
            </Tabs>
        </div>
    );
};

export default Landing;
