// UsersList.tsx
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import UserSelection from '../UserSelection/UserSelection';

interface UsersListProps {
  onInviteUser: () => void;
}

const UsersList: React.FC<UsersListProps> = ({ onInviteUser }) => {
  return (
    <div className='px-4'>
     <NavLink to="/invite" className="nav-link mt-4 mb-n2">
        <Button variant="outline-dark" className="my-2" onClick={onInviteUser}>
          <FontAwesomeIcon className="mx-2" icon={faUserPlus}></FontAwesomeIcon>Add a patient
        </Button>
      </NavLink>
      <UserSelection />
    </div>
  );
};

export default UsersList;