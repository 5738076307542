import React, { CSSProperties } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export enum FileProcessingStatus{
    Started='started',
    Success='success',
    Failure='failure'
}

export interface FileProcessingEvent {
    event: string;
    status: FileProcessingStatus;
    content?: string | object;
    timeStamp: number,
    startTime?: number,
    endTime?: number,
    duration?:number
}

interface StatusBadgeProps {
    fileProcessingTracking: FileProcessingEvent[];
    mode: 'simple' | 'detailed';
}


const StatusBadge: React.FC<StatusBadgeProps> = ({ fileProcessingTracking , mode }) => {
    const itemEventMapping: Record<string, string> = {
        'accessingFile': 'Retrieving',
        'transcribeAudio': 'Transcribing',
        'conversationOrDictation': 'Categorizing',
        'transcribeToDialogue': 'Formatting',
        'annotation': 'Annotating',
        'patientSummary': 'Summarizing',
        'postProcessing': 'Refining',
    };
    const uniqueEvents = Object.values(fileProcessingTracking.reduce((acc: Record<string, FileProcessingEvent>, item: FileProcessingEvent) => {
        if (!acc[item.event]) {
            acc[item.event] = { ...item, startTime: item.timeStamp, endTime: item.timeStamp };
        }
        else { // keep the same acc[item.event], just update the status from item
            acc[item.event] = { ...acc[item.event],content:item.content, status: item.status, endTime: item.timeStamp };
        }
        return acc;
    }, {}));
    uniqueEvents.sort((a, b) => a.timeStamp - b.timeStamp!);

    const getBadgeClass = (status: FileProcessingStatus) => {
        switch (status) {
            case 'started':
                return 'bg-warning fa-beat-fade';
            case 'success':
                return 'bg-primary';
            case 'failure':
                return 'bg-danger';
            default:
                return '';
        }
    }


    const filteredItems = uniqueEvents.filter(item => item.event !== "fileProcess").map(item=>({...item,duration:item.endTime! - item.startTime!}));
    const totalProcessTime = filteredItems.reduce((acc, item) => acc + (item.duration), 0);
    const processComplete = React.useMemo(() => 
        uniqueEvents.some(item => item.event === 'fileProcess' && item.status === 'success')
    , [uniqueEvents]);

    

    const badgeContainerStyle = mode === 'detailed' ? { flexDirection: 'column' } : {};
    const badgeSpacing = mode === 'simple' ? { marginRight: '1.5rem' } : {};

    const shouldRender = () => {
        return (!processComplete && mode === "simple") || mode === 'detailed';
    }
    const shouldRenderTooltip = sessionStorage.getItem('debugMode') === 'true';
    return shouldRender() ? (
        <>
            <div className={`d-flex ${mode==='detailed'?'p-5':''}`} style={badgeContainerStyle as CSSProperties}>
                <div style={badgeSpacing}></div>                
                <ul className={`list-inline`}>
                    {filteredItems.filter((item: { event: string; }) => item.event !== "fileProcess").map((item: FileProcessingEvent, index: number, array) => {
                        const badgeStyle = mode === 'detailed' && processComplete ? { width: `${85 * (item.duration!) / totalProcessTime}%` } : {};
                        const tooltip =  (shouldRenderTooltip && (
                            <Tooltip id={`tooltip-${index}`}>
                                <div>sec {(item.duration! / 1000).toFixed(2)}</div>
                                <div>{typeof item.content === 'object' ? JSON.stringify(item.content) : item.content}</div>
                            </Tooltip>
                        )) || <></>;
                        return (
                            <OverlayTrigger key={index} placement="right" overlay={tooltip}>
                                <li className={`list-inline-item`} style={badgeStyle}>
                                    <div className='d-flex mt-1'>
                                        <span className={`text-truncate flex-fill badge ${getBadgeClass(item.status)}`}>
                                        {itemEventMapping[item.event] || 'Uncategorized'}
                                        </span>
                                        {index !== (array.length - 1) && ' → '}
                                    </div>
                                </li>
                            </OverlayTrigger>
                        );
                    })}
                </ul>
            </div>
        </>
    ) : null;
}

export default StatusBadge;