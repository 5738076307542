import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where, DocumentData, limit, orderBy, startAfter, getFirestore } from 'firebase/firestore';
import { RecordingFilter } from '../UserRecordings/UserRecordings';


const useFiles = (userId: string, selectedUserUid: string, filter: Partial<RecordingFilter>) => {
    const [files, setFiles] = useState<DocumentData[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // const getFileProcessingTracking = useCallback(async (fileDocRef: DocumentReference<DocumentData>) => {

    //     try {
    //         const fileProcessingTrackingRef = collection(fileDocRef, 'fileProcessingTracking');
    //         const snapshot = await getDocs(fileProcessingTrackingRef);
    //         const result = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as { event: string; status: string; id: string }));


    //         return result;
    //     } catch (error) {
    //         throw error;
    //     }
    // }, []);

    useEffect(() => {

        setIsLoading(true);
        const db = getFirestore();
        const filesCollectionPath = `recordings/${userId}/users/${selectedUserUid}/files`;
        console.info('filesCollectionPath',filesCollectionPath);
        const filesCollectionRef = collection(db, filesCollectionPath);

        const queryParams = [];

        if (filter.startAfter) {
            queryParams.push(startAfter(filter.startAfter));
        }
        if (filter.overallStatusIn) {
            queryParams.push(where('overallStatus', 'in', filter.overallStatusIn), orderBy('overallStatus'));
        }
        if (filter.overallStatusNotIn) {
            queryParams.push(where('overallStatus', 'not-in', filter.overallStatusNotIn), orderBy('overallStatus'));
        }
        if (filter.limit) {
            queryParams.push(orderBy('timeStamp', 'desc'), limit(filter.limit));
        }

        const filesQuery = query(filesCollectionRef, ...queryParams);

        const unsubscribe = onSnapshot(filesQuery, async (snapshot) => {

            try {
                const filesData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        overallStatus: data.overallStatus,
                        fileProcessingTracking: data.fileProcessingSteps,
                    };
                }).sort((a, b) => Number(b.id) - Number(a.id));

                setFiles(filesData);
                console.table(filesData);

            } catch (error) {
                console.error("Error fetching file processing data:", error);
            } finally {
                setIsLoading(false);
            }
        });


        return () => {
            unsubscribe();
        };
    }, [selectedUserUid, userId, filter]);

    return { files, isLoading };
};

export default useFiles;