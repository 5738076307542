import React, { useCallback, useEffect, useState } from 'react';
import { DocumentData } from 'firebase/firestore';
import { Button, Table, InputGroup, FormControl } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import apiFetch from '../../services/apiFetch';
import { Auth } from 'firebase/auth';
import styles from './UserSelection.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserRecord } from '../../services/fetchUser';

interface UserSelectionProps { }

const usersCache: { [key: string]: UserRecord[] } = {};
export const clearUsersCache = () => {
  Object.keys(usersCache).forEach(key => delete usersCache[key]);
};

const UserSelection: React.FC<UserSelectionProps> = () => {
  const [users, setUsers] = useState<UserRecord[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const { authPingooClinic }: { authPingoo: Auth, authPingooClinic: Auth } = require('../../firebaseConfig');
  const navigate = useNavigate();
  const debugMode = sessionStorage.getItem('debugMode') === 'true';
  const location = useLocation();
  const shouldFetchUsers = location.state?.fetchUsers;

  const fetchChildUsers = useCallback(async (): Promise<DocumentData[]> => {
    const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
    try {
    const response = await apiFetch(`${apiUrl}/webClient/childUsers`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }, authPingooClinic);
    if (response?.ok) {
      const jsonData = await response?.json();
      return jsonData;
    } else {
      console.error("Request failed with status: " + response?.status);
      console.error('Error signing into Project Pingoo-clinic with custom token:', response);
      return [];
    }
  } catch (error) {
    return [];
  }
  }, [authPingooClinic]);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const cacheKey = 'users';
    try {
      if (usersCache[cacheKey]) {
        setUsers(usersCache[cacheKey]);
      } else {
        const childUsers = await fetchChildUsers();
        const usersData: UserRecord[] = childUsers.map(doc => ({
          uid: doc.uid, // User ID
          firstName: doc.firstName,
          lastName: doc.lastName,
          email: doc.email,
          autoGeneratedEmail: doc.autoGeneratedEmail,
          displayName: doc.displayName as string,
          linkedIds:doc.linkedIds,
          parentIds:doc.parentIds,
        }));

        usersCache[cacheKey] = usersData;
        setUsers(usersData);
      }
    } finally {
      setLoading(false);
    }
  }, [fetchChildUsers]);

  const handleDelete = async (user: UserRecord) => {
    const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
    const response = await apiFetch(`${apiUrl}/webClient/childUsers/${user.uid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    }, authPingooClinic);
    if (response?.ok) {
      const usersData = users.filter(u => u.uid !== user.uid);
      setUsers(usersData);
    } else {
      console.error("Request failed with status: " + response?.status);
      console.error('Error deleting user:', response);
    }
  };

  useEffect(() => {
    if (shouldFetchUsers) {
      // empty usersCache by iterating over keys and deleting them
      Object.keys(usersCache).forEach(key => delete usersCache[key]);
    }
      fetchUsers();
  }, [fetchUsers, shouldFetchUsers]);

  const filteredUsers = users.filter(user =>
    user.uid.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="my-4">
        <div className={styles.searchBar}>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              placeholder="Search by ID, Name or Email"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>
        {loading ? (
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faSpinner} size='2x' spin />
          </div>
        ) : (
          <div style={{ maxHeight: 'calc(100vh - 250px)' }} className='overflow-auto'>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr
                    key={user.uid}
                    className={styles.clickableRow}
                    onClick={() => navigate(`/user/${user.uid}/newRecording`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{user.linkedIds[0]}</td>
                    <td>{user.displayName || `${user.firstName} ${user.lastName}` }</td>
                    <td>{(!user.autoGeneratedEmail && user.email) || ''}</td>
                    <td>
                     <span>
                     <Button
                        variant="outline-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/user/${user.uid}/newRecording`);
                        }}
                      >
                        Actions
                      </Button>  
                      {debugMode && <FontAwesomeIcon  className="ms-3 text-danger" icon={faTrash} onClick={(e)=>{ e.stopPropagation();handleDelete(user)}} /> }            
                      </span>

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default UserSelection;
