import React from 'react';
import { Image } from 'react-bootstrap';
// You may need to import a chart library to render the pie chart

const GeneticSummaryReport: React.FC = () => {
  return (
    <div className="mt-4">
      <h6>Pingoo.AI for your patients and clients</h6>
      <div>
      <Image thumbnail  fluid src='/dashboard_lower.png' />  
  </div>
</div>
  );
};

export default GeneticSummaryReport;
