import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';

const GoToPatientsList: React.FC = () => {
    return (
            <NavLink className={'text-primary'} to={`/users`} style={{ textDecoration: 'none' }}>
                <FontAwesomeIcon icon={faLessThan} className='me-2' /> <span className='d-none d-sm-inline'>Patients List</span>
            </NavLink>

    );
};

export default GoToPatientsList;