import React, { useState } from 'react';
import { Form, Button, Toast } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import GoToPatientsList from '../GoToPatientsList/GoTpPatientsList';
import { AddUserRecord, addChildUser } from '../../services/addChildUser';

const InviteUser: React.FC = () => {
    const [email, setEmail] = useState('');
    const [linkedId, setLinkedId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [validated, setValidated] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            const childUser: AddUserRecord = {
                email: email.trim(),
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                linkedId: linkedId.trim()
            };
            console.log('Submitting form:', childUser);
            try {
                await addChildUser(childUser);
                navigate('/users',{state:{fetchUsers:true}});
            } catch (error:any) {
                setToastMessage(error.message);
                setShowToast(true);
            }
        }
        setValidated(true);
    };

    return (
        <div className="p-4 w-100">
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} bg={'danger'} autohide>
                <Toast.Header>
                    <strong className="mr-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <span className='d-flex'>
                <GoToPatientsList />
            </span>
            <Form noValidate validated={validated} className='container py-5' onSubmit={handleSubmit}>
                <Form.Group className='mb-3'>
                    <Form.Label htmlFor='firstName'>First Name:</Form.Label>
                    <Form.Control
                        required
                        maxLength={50}
                        type='text'
                        id='firstName'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a first name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label htmlFor='lastName'>Last Name:</Form.Label>
                    <Form.Control
                        required
                        maxLength={50}
                        type='text'
                        id='lastName'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a last name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label htmlFor='email'>Email:</Form.Label>
                    <Form.Control
                        maxLength={100}
                        type='email'
                        id='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label htmlFor='linkedId'>Patient ID:</Form.Label>
                    <Form.Control
                        required
                        maxLength={64}
                        type='text'
                        id='linkedId'
                        value={linkedId}
                        onChange={(e) => setLinkedId(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a patient ID.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className='text-start'>
                <Button type='submit' className='btn-primary text-white mt-3'>
                    Add Patient
                </Button>
                <NavLink to="/users" className="btn btn-outline-danger ms-2 mt-3">Cancel</NavLink>
                </div>
            </Form>
        </div>
    );
};

export default InviteUser;