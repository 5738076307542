import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Button, FormControl, FormGroup, FormLabel, Toast } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

var { authPingoo } = require('../../firebaseConfig');

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [passwordOne, setPasswordOne] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [validated, setValidated] = useState(false);
    const [success, setSuccess] = useState<string>(''); // new state variable for success message
    const [showToast, setShowToast] = useState(false); // new state variable for showing toast
    const [loading, setLoading] = useState(false); // new state variable for loading

    useEffect(() => {
        if (showToast === true && success) {
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
    }, [navigate, success, showToast]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setError('')
            if (passwordOne === passwordTwo) {
                try {
                    setLoading(true)
                    const userCredential = await createUserWithEmailAndPassword(authPingoo, email, passwordOne);
                    const user = userCredential.user;
                    await sendEmailVerification(user);
                    console.log('Email verification sent!');
                    setSuccess("Success. Your user is created successfully.");
                    setShowToast(true); // show the toast
                } catch (error: any) {
                    setError(error?.message?.replace('Firebase:', ''));
                    setShowToast(true);
                } finally {
                    setLoading(false)
                }
            }
            else {
                setError("Password do not match");
            }
        }
    };

    return (
        <Container className="text-center" style={{ padding: '40px 0px' }}>
            {loading && (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark z-2 bg-opacity-25">
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
            )}
            <Row>
                <Col>
                    <Form noValidate validated={validated} style={{ maxWidth: '400px', margin: 'auto' }} onSubmit={onSubmit}>
                        <Toast onClose={() => setShowToast(false)} show={showToast || false} delay={3000} autohide bg={error ? 'danger' : 'success'}>
                            <Toast.Header>
                                <strong className="me-auto">{error ? 'Error' : 'Success'}</strong>
                            </Toast.Header>
                            <Toast.Body className='text-white'>{success || error}</Toast.Body>
                        </Toast>
                        <img
                            width={256}
                            height={256}
                            className="img-fluid"
                            src='/logo.png'
                            alt="product images"
                        />

                        <h2 className='my-3'>Create your account</h2>
                        <p className='mb-5'>You need a valid email address to continue</p>
                        <FormGroup as={Row} className="mb-3">
                            <FormLabel column sm={4}>Email</FormLabel>
                            <Col sm={8}>
                                <FormControl
                                    minLength={6}
                                    type="email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    name="email"
                                    id="signUpEmail"
                                    placeholder="Email"
                                    required
                                    isInvalid={validated && (email === "" || email.length < 6)} />
                                <Form.Control.Feedback type="invalid">
                                    {email === "" ? "Please provide an email." : "Email should be at least 6 characters long."}
                                </Form.Control.Feedback>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className="mb-3">
                            <FormLabel column sm={4}>Password</FormLabel>
                            <Col sm={8}>
                                <FormControl
                                    type="password"
                                    name="passwordOne"
                                    minLength={6}
                                    value={passwordOne}
                                    onChange={(event) => setPasswordOne(event.target.value)}
                                    id="signUpPassword"
                                    placeholder="Password"
                                    required
                                    isInvalid={validated && (passwordOne === "" || passwordOne.length < 6)} />
                                <Form.Control.Feedback type="invalid">
                                    {passwordOne === "" ? "Please provide a password." : "Password should be at least 6 characters long."}
                                </Form.Control.Feedback>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className="mb-3">
                            <FormLabel column sm={4}>Confirm Password</FormLabel>
                            <Col sm={8}>
                                <FormControl
                                    type="password"
                                    name="password"
                                    value={passwordTwo}
                                    onChange={(event) => setPasswordTwo(event.target.value)}
                                    id="signUpPassword2"
                                    placeholder="Password"
                                    required
                                    isInvalid={validated && (passwordTwo === "" || passwordTwo.length < 6)} />
                                <Form.Control.Feedback type="invalid">
                                    {passwordTwo === "" ? "Please confirm your password." : "Confirmation password should be at least 6 characters long."}
                                </Form.Control.Feedback>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Col>
                                <Button variant="primary" className='w-100 text-white' type="submit">Sign Up</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default SignUp;