import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';

interface CopyButtonProps {
	text: string;
	label: string;
	copyKey: string;

}

const CopyButton: React.FC<CopyButtonProps> = ({ text, label, copyKey }) => {
    const [copiedStates, setCopiedStates] = useState<{ [key: string]: boolean }>({});

    const handleCopy = (key: string) => {
        setCopiedStates(prev => ({ ...prev, [key]: true }));
        setTimeout(() => {
            setCopiedStates(prev => ({ ...prev, [key]: false }));
        }, 2000); // Reset after 2 seconds
    };
	
    return <CopyToClipboard text={text} onCopy={() => handleCopy(copyKey)}>
		<button className={`btn ${copiedStates[copyKey] ? 'btn-success' : 'btn-outline-dark'} btn-sm`}>
			<FontAwesomeIcon icon={copiedStates[copyKey] ? faCheck : faCopy} />
			{' '}
			{copiedStates[copyKey] ? 'Copied!' : label}
		</button>
	</CopyToClipboard>
};

export default CopyButton;