import { RecordingStatus } from '../RecordingComponent/RecordingComponent';
import { ProgressBar } from 'react-bootstrap';

interface FileUploaderProps {
    currentStatus: RecordingStatus;
    currentProgress?: number;
}

const UploadProgress = ({ currentStatus, currentProgress }: FileUploaderProps) => {
    return (
        currentStatus === RecordingStatus.Uploading && (
            <div className="d-flex flex-row mb-3">
                <strong className='flex-grow-1'>Current Recording Upload Status:</strong>
                <span className='my-auto flex-grow-1'>
                    <ProgressBar now={currentProgress} />
                </span>
            </div>
        )
    );
};

export default UploadProgress;