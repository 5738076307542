import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RouteChangeMessage {
  type: 'CHANGE_PATIENT';
  patientId: string;
}

const isRouteChangeMessage = (message: any): message is RouteChangeMessage => {
  return message && typeof message === 'object' &&
    message.type === 'CHANGE_PATIENT' &&
    typeof message.patientId === 'string';
};

const IframeRouteHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Ensure the message is from the parent window
      if (event.source !== window.parent) {
        console.log('Received message from an unknown source');
        return;
      }

      console.log('Received message:', event.data);

      if (isRouteChangeMessage(event.data)) {
        const { patientId } = event.data;
        console.log(`Changing route to patient/${patientId}`);
        navigate(`/patient/${patientId}`);
      } else {
        console.log('Received unknown message type or invalid message format');
      }
    };

    // Add the event listener
    window.addEventListener('message', handleMessage);

    // Log that the listener has been set up
    console.log('Message listener set up');

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
      console.log('Message listener removed');
    };
  }, [navigate]);

  // This component doesn't render anything visible
  return null;
};

export default IframeRouteHandler;