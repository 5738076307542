import React, { useState } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';

import { GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';
import { GoogleLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';
import loginPingooClinic from '../../services/loginPingooclinic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { clearUsersCache } from '../../components/UserSelection/UserSelection';
var { authPingoo } = require('../../firebaseConfig');



class MicrosoftAuthProvider extends OAuthProvider {
  constructor() {
    super('microsoft.com');
  }
}

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      setError(null);
      await signInWithEmailAndPassword(authPingoo, email, password);
      await loginPingooClinic();
      clearUsersCache();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setLoading(false);
    }
  }


  // Handle sign in with Google
  const handleGoogleSignIn = async () => {
    setError(null);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(authPingoo, provider);
      setLoading(true);
      await loginPingooClinic();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setLoading(false);
    }
  }

  // Handle sign in with Microsoft
  const handleMicrosoftSignIn = async () => {
    setError(null);
    try {
      const provider = new MicrosoftAuthProvider();
      await signInWithPopup(authPingoo, provider);
      setLoading(true);
      await loginPingooClinic();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setLoading(false);
    }
  }

  const showSocialLogin = true;

  return (
    <div className={styles.container}>
      {loading && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark z-2 bg-opacity-25">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}

      <Form className={styles.form} onSubmit={handleLogin}>
        <div className="text-center mb-3"><Image width="170px" src='logo.png' fluid thumbnail /></div>
        <Form.Group className='mb-3'>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button className={`mt-4 ${styles.submitButton}`} color="black" variant='outline-dark' type="submit">
          Login
        </Button>

        {showSocialLogin && (
          <>
            <div className='my-3'></div>
            <Form.Group as={Row}>
              <Col>
                <div className='text-center'>Don't have an account?</div>
                <div>
                  <Button onClick={() => navigate('/signup')} className={`mt-3 btn-primary text-white mb-3 ${styles.submitButton}`}>
                    Sign up with Email
                  </Button>
                </div>
              </Col>
            </Form.Group>
            <div className='row'>
              <span className='col'><hr /></span><span className='col-auto my-auto g-0'>OR</span><span className='col'><hr /></span>
            </div>
            {false && <Form.Group as={Row}>
              <Col>
                <MicrosoftLoginButton text="Sign in with Microsoft" align='center' onClick={handleMicrosoftSignIn} />
              </Col>
            </Form.Group>}
            <Form.Group as={Row}>
              <Col>
                <GoogleLoginButton text="Sign in with Google" align='center' className="border" onClick={handleGoogleSignIn} />
              </Col>
            </Form.Group>
            <Form.Group className='mt-3 text-center' as={Row}>
              <Col>
                <a href="https://silverberry.health/terms-of-use" target="_blank" rel="noopener noreferrer" className={styles.linkButton}>
                  Terms Of use
                </a>
              </Col>
              <Col>
                <a href="https://silverberry.health/privacy-policy" target="_blank" rel="noopener noreferrer" className={styles.linkButton}>
                  Privacy Policy
                </a>
              </Col>
            </Form.Group>
          </>
        )}

      </Form>
      <span className="txt-danger">{error}</span>
    </div>
  );
};

export default Login;
