import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Assume this is your API function to fetch patient data
import { fetchChildUser, UserRecord } from '../services/fetchUser';
import UserAction from './UserAction/UserAction';

const PatientWrapper = () => {
  const { patientId } = useParams<string>();
  const [patientData, setPatientData] = useState<UserRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPatientData = async () => {
        if (!patientId) {
            return;
        }
      setLoading(true);
      try {
        const data = await fetchChildUser(patientId, true);
        setPatientData(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching patient data:', err);
        setError('Failed to load patient data. Please try again.');
        setPatientData(null);
      } finally {
        setLoading(false);
      }
    };

    loadPatientData();
  }, [patientId]);

  if (loading) {
    return <></>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <UserAction patient={ patientData as UserRecord} action="newRecording" />;
};

export default PatientWrapper;