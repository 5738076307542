import{ initializeApp } from 'firebase/app';
import 'firebase/auth';
import { Auth, getAuth } from 'firebase/auth';

const firebaseConfigPingoo = {
  apiKey: "AIzaSyAK_j8r7l6hHbn-y2vd3KuPU5KVeQ9JFB0",
  authDomain: "innovearn.firebaseapp.com",
  databaseURL: "https://innovearn.firebaseio.com",
  projectId: "innovearn",
  storageBucket: "innovearn.appspot.com",
  messagingSenderId: "605460580335",
  appId: "1:605460580335:web:966381d7d76d81448cd81a",
  measurementId: "G-HKR0Y51DH4"
};
const appPingoo =initializeApp(firebaseConfigPingoo,'pingoo');
const authPingoo: Auth = getAuth(appPingoo);

const firebaseConfig = {
  apiKey: "AIzaSyAUyXkTy6yC1TyasLABZnxuo4LN-harDlc",
  authDomain: "pingoo-clinic.firebaseapp.com",
  projectId: "pingoo-clinic",
  storageBucket: "pingoo-clinic.appspot.com",
  messagingSenderId: "614973752077",
  appId: "1:614973752077:web:cd52ff8ec0ec35a16f6644",
  measurementId: "G-T8P5BEKKPK"
};
const appPingooClinic=initializeApp(firebaseConfig);
const authPingooClinic:Auth = getAuth(appPingooClinic)



export { authPingoo, authPingooClinic};

