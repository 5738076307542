import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavLink, NavbarBrand, Image } from 'react-bootstrap';

import { forwardRef, useState } from 'react';
import { clearUsersCache } from '../UserSelection/UserSelection';
var {authPingooClinic} = require('../../firebaseConfig');

interface HeaderProps {
  toggleMenu: () => void;
}

// Wrap the component with forwardRef
const Header = forwardRef<HTMLDivElement, HeaderProps>(({ toggleMenu }, ref) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleLogout = async () => {
    try {
      await authPingooClinic.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  return (
    <Navbar ref={ref} bg="primary" variant="dark" expand="lg" expanded={expanded}>
      <Navbar.Toggle  className="me-3"  aria-controls="basic-navbar-nav" onClick={()=>{setExpanded(!expanded);toggleMenu()}} />
      <NavbarBrand>
        <Image className="ms-3 me-3" src="/logo.png" width={30} alt="Logo" />
        Silverberry Ambient AI
      </NavbarBrand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav  className="ms-auto me-4">
          <NavLink className="text-white" onClick={handleLogout}>Logout</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

  );
});

export default Header;
