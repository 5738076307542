import React from 'react';

// Assuming you have components for each of these sections or use a library for charts
import GlucoseOverview from './GlucoseOverview/GlucoseOverview';
import GeneticSummaryReport from './GeneticSummaryReport/GeneticSummaryReport';
import Recommendations from './Recommendations/Recommendations';

type DashboardProps = {

};

const Dashboard: React.FC<DashboardProps> = (props) => {
    // You can manage state or fetch data here

    return (
        <div className="row overflow-y-auto
    " style={{ maxHeight: 'calc(100vh - 105px)' }}>
            <div className='p-4 col-12 col-xl-7'>
                <GlucoseOverview />
                <GeneticSummaryReport />

            </div>
            <div className='p-4 col-12 col-xl-4'>
                <Recommendations />
            </div>




        </div>
    );
};

export default Dashboard;
