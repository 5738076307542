import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

// Step 1: Define a type for your props
interface UploadButtonProps {
    onFileSelected: (file: File) => void | Promise<void>;
}

// Update the component to accept props
const UploadButton: React.FC<UploadButtonProps> = ({ onFileSelected }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            return;
        }
        const file = files[0];

        // Step 2: Use the passed function with the selected file
        try {
            await onFileSelected(file);
        } catch (error) {
            console.error('Error in handling file:', error);
            alert('Error in handling file, please check the console for more information.');
        }
    };

    return (
        <div className="d-flex m-auto">
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="audio/*"
                style={{ display: 'none' }}
            />
            <Button
                 size="lg"
                 variant='secondary'
                onClick={handleUploadClick}
                className="upload-btn  text-white"
            >
                <FontAwesomeIcon icon={faUpload} /> Upload Recording
            </Button>
        </div>
    );
};

export default UploadButton;
