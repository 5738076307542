import React, { useState } from 'react';
import CopyButton from '../../CopyButton/CopyButton';
import { Button, Modal } from 'react-bootstrap';
import fetchWithBearerToken from '../../../services/apiFetch';
import { UserRecord } from '../../../services/fetchUser';
import { authPingooClinic } from '../../../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface ISoapNote {
  subjective: {
    chiefComplaint: string;
    HPI: string;
    history: {
      medicalHistory: string[];
      surgicalHistory: string[];
      familyHistory: string[];
      socialHistory: string[];
    };
    ROS: string[];
    currentMedicationAndAllergies: string[];
  };
  objective: {
    vitalSigns: {
      height: string;
      weight: string;
      BMI: string;
      BMIPercentile: string;
      BP: string;
      temperature: string;
      pulse: string;
      respiratoryRate: string;
      O2Saturation: string;
      pain: string;
      headCircumference: string;
    };
    physicalExam: string[];
    laboratoryData: string[];
  };
  assessment: string[];
  plan: string[];
  edits?: { text: string, editedAt: number, approved: boolean }[];
}

export function formatSoapNoteToString(soapNote: ISoapNote): string {
  const bulletList = (items: string[]) => Array.isArray(items) ? items.map(item => `- ${item}`).join('\n') : '';
  let formattedString = `SOAP Note:\n\n`;

  formattedString += `Subjective:\n`;
  formattedString += `Chief Complaint: ${soapNote.subjective.chiefComplaint}\n`;
  formattedString += `HPI: ${soapNote.subjective.HPI}\n`;
  formattedString += `History:\n`;
  formattedString += `  Medical History:\n${bulletList(soapNote.subjective.history.medicalHistory)}\n`;
  formattedString += `  Surgical History:\n${bulletList(soapNote.subjective.history.surgicalHistory)}\n`;
  formattedString += `  Family History:\n${bulletList(soapNote.subjective.history.familyHistory)}\n`;
  formattedString += `  Social History:\n${bulletList(soapNote.subjective.history.socialHistory)}\n`;
  formattedString += `ROS:\n${bulletList(soapNote.subjective.ROS)}\n`;
  formattedString += `Current Medication and Allergies:\n${bulletList(soapNote.subjective.currentMedicationAndAllergies)}\n\n`;

  formattedString += `Objective:\n`;
  formattedString += `Vital Signs:\n`;
  Object.entries(soapNote.objective.vitalSigns).forEach(([key, value]) => {
    formattedString += `  ${key}: ${value}\n`;
  });
  formattedString += `Physical Exam:\n${bulletList(soapNote.objective.physicalExam)}\n`;
  formattedString += `Laboratory Data:\n${bulletList(soapNote.objective.laboratoryData)}\n\n`;

  formattedString += `Assessment:\n${bulletList(soapNote.assessment)}\n\n`;
  formattedString += `Plan:\n${bulletList(soapNote.plan)}\n`;

  return formattedString;
}

interface SoapNoteProps {
  note: ISoapNote;
  fileId: string;
  selectedUser: UserRecord;
}

const SoapNote: React.FC<SoapNoteProps> = ({ note, fileId, selectedUser }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState('');
  const [isApproving, setIsApproving] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const lastEdit = note.edits && note.edits[note.edits.length - 1];
  const isApproved = lastEdit && lastEdit.approved;
  const approvedText = isApproved ? lastEdit.text : null;

  const handleEdit = () => {
    setIsEditing(true);
    setEditedText(formatSoapNoteToString(note));
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedText('');
  };

  const handleApproveClick = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmApprove = async () => {
    setShowConfirmDialog(false);
    setIsApproving(true);
    try {
      const apiUrl = process.env.REACT_APP_FIREBASE_API_URL;
      const response = await fetchWithBearerToken(
        `${apiUrl}/webClient/childUsers/${selectedUser.uid}/recordings/${fileId}/soapNote`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text: editedText }),
        },
        authPingooClinic
      );
      if (response?.ok) {
        const data = await response.json();
        console.log('SOAP Note updated:', data);
        setIsEditing(false);
        // You might want to update the note state here with the new data
      } else {
        console.error('Error updating SOAP Note:', response?.statusText);
      }
    } catch (error) {
      console.error('Error during SOAP Note update:', error);
    }
    setIsApproving(false);
  };

  const handleCancelApprove = () => {
    setShowConfirmDialog(false);
  };

  if (isEditing) {
    return (
      <>
        <textarea
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          rows={20}
          className="form-control mb-3"
        />
        <div className="d-flex justify-content-end">
          <Button variant="primary" className='text-white' onClick={handleApproveClick} disabled={isApproving}>
            {isApproving ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Approve'}
          </Button>
          <Button variant="outline-danger" onClick={handleCancel} className="ms-2">
            Cancel
          </Button>
        </div>

        {/* Confirmation Dialog */}
        <Modal show={showConfirmDialog} onHide={handleCancelApprove}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to approve these changes? Once approved, they cannot be modified later.
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" className='text-white' onClick={handleConfirmApprove}>
              Yes, Approve
            </Button>
            <Button variant="outline-danger" onClick={handleCancelApprove}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  if (approvedText) {
    return (
      <>
        <textarea
          value={approvedText}
          readOnly
          rows={20}
          className="form-control mb-3"
        />
        <div className="d-flex justify-content-between align-items-center">
          <CopyButton
            text={approvedText}
            label="Copy SOAP Note"
            copyKey={`soap-${fileId}`}
          />
          <span className="text-success">
            Approved on {new Date(lastEdit!.editedAt).toLocaleString()}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ padding: '20px' }}>
        <ul>
          <li>
            <strong>Subjective:</strong>
            <ul>
              <li>
                <strong>Chief Complaint: </strong>
                {note.subjective.chiefComplaint}
              </li>
              <li>
                <strong>HPI: </strong>
                {note.subjective.HPI}
              </li>
              <li>
                <strong>History: </strong>
                <ul>
                  <li>
                    <strong>Medical History: </strong>
                    {note.subjective.history.medicalHistory.join(', ')}
                  </li>
                  <li>
                    <strong>Surgical History: </strong>
                    {note.subjective.history.surgicalHistory.join(', ')}
                  </li>
                  <li>
                    <strong>Family History: </strong>
                    {note.subjective.history.familyHistory.join(', ')}
                  </li>
                  <li>
                    <strong>Social History: </strong>
                    {note.subjective.history.socialHistory.join(', ')}
                  </li>
                </ul>
              </li>
              <li>
                <strong>ROS: </strong>
                <ul>
                  {note.subjective.ROS.map((ros, index) => (
                    <li key={index}>{ros}</li>
                  ))}
                </ul>
              </li>
              <li>
                <strong>Current Medication and Allergies: </strong>
                <ul>
                  {note.subjective.currentMedicationAndAllergies.map((med, index) => (
                    <li key={index}>{med}</li>
                  ))}
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>Objective:</strong>
            <ul>
              <li>
                <strong>Vital Signs: </strong>
                <ul>
                  {Object.entries(note.objective.vitalSigns).map(([key, value], index) => (
                    <li key={index}>{`${key}: ${value}`}</li>
                  ))}
                </ul>
              </li>
              <li>
                <strong>Physical Exam: </strong>
                <ul>
                  {note.objective.physicalExam.map((exam, index) => (
                    <li key={index}>{exam}</li>
                  ))}
                </ul>
              </li>
              <li>
                <strong>Laboratory Data: </strong>
                <ul>
                  {note.objective.laboratoryData.map((data, index) => (
                    <li key={index}>{data}</li>
                  ))}
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>Assessment:</strong>
            <ul>
              {note.assessment.map((assessment, index) => (
                <li key={index}>{assessment}</li>
              ))}
            </ul>
          </li>
          <li>
            <strong>Plan:</strong>
            <ul>
              {note.plan.map((plan, index) => (
                <li key={index}>{plan}</li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-end">
        <Button variant="outline-primary" onClick={handleEdit}>
          Edit draft
        </Button>
      </div>
    </>
  );
};

export default SoapNote;