import React, { useState } from 'react';
import RecordingComponent, { RecordingStatus } from '../RecordingComponent/RecordingComponent';
import UserRecordings, { RecordingsViewMode } from '../UserRecordings/UserRecordings';
import UploadProgress from '../UploadProgress/UploadProgress';
import { UserRecord } from '../../services/fetchUser';


const UserRecordingDashboard = ({ selectedUser }: { selectedUser: UserRecord }) => {
    const [currentStatus, setCurrentStatus] = useState<RecordingStatus>(RecordingStatus.Idle);
    const [currentProgress, setCurrentProgress] = useState<number | undefined>(0);
    const [fileId,setFileId] = useState<string>('');
    const [showLast,setShowLast]= useState<boolean>(true)

    const handleStatusUpdate = (status: RecordingStatus,fileId?:string, progress?: number) => {
        setCurrentStatus(status);
        setCurrentProgress(progress);
        setFileId((status === RecordingStatus.UploadComplete && fileId) || '');
        console.log(status, progress);
    };

    const HandleOnRecordingsCountUpdate =(newCount: Number) => {
        setShowLast(newCount===0);
    }

    const handelRecordingDeleted =(_fileId: string)=>{
        (_fileId===fileId) && setFileId('');
    }

    return (
        <>
            <div className="px-4 overflow-y-auto overflow-x-hidden d-flex flex-column w-100" style={{maxHeight: 'calc(100vh - 190px)'}}>
                <RecordingComponent selectedUser={selectedUser} onStatusUpdate={handleStatusUpdate} />
                <UploadProgress currentStatus={currentStatus} currentProgress={currentProgress} />
                <UserRecordings showLoader={false} onRecordingDeleted={handelRecordingDeleted} onRecordingsCountUpdate={HandleOnRecordingsCountUpdate} selectedUser={selectedUser} currentFileId={fileId} viewMode={RecordingsViewMode.Pending}></UserRecordings>
                 <UserRecordings className={showLast?'':'d-none'} onRecordingDeleted={handelRecordingDeleted} selectedUser={selectedUser} currentFileId={fileId} viewMode={RecordingsViewMode.Last}></UserRecordings>
        </div>
        </>
    );
};

export default UserRecordingDashboard;