// UserRecordingDashboard.tsx
import { useState, useRef } from 'react';
import { Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStopCircle } from '@fortawesome/free-solid-svg-icons';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import UploadButton from '../UploadButton/UploadButton';
import styles from './RecordingComponent.module.scss';
import { UserRecord } from '../../services/fetchUser';


export enum RecordingStatus{
    Idle="Idle",
    RecordingStarted = "Recording started",
    RecordingStopped = "Recording stopped",
    Uploading = "Uploading",
    UploadComplete = "Upload complete",
    UploadError = "Upload error"    
}


interface RecordingComponentProps {
    selectedUser: UserRecord;
    onStatusUpdate: (status: RecordingStatus,id?:string, progress?: number) => void; // New prop for status updates
  }
  
  const RecordingComponent = ({ selectedUser, onStatusUpdate }: RecordingComponentProps) => {
  
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
   
    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
        onStatusUpdate(RecordingStatus.RecordingStarted);
    };

    const stopRecording = async () => {
        if (!mediaRecorderRef.current) return;
        onStatusUpdate(RecordingStatus.RecordingStopped);
        
        return new Promise<void>((resolve) => {
            mediaRecorderRef.current!.onstop = async () => {
                setIsRecording(false);
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                audioChunksRef.current = [];
                await uploadAudioFirebase(audioBlob);
                resolve();
            };

            mediaRecorderRef.current!.stop();
            mediaRecorderRef.current?.stream.getTracks().forEach(track => track.stop())
        });
    };

    const uploadAudioFirebase = async (blob: Blob | File) => {
        const fileId = Date.now().toString();
        try {
            var {authPingooClinic} = require('../../firebaseConfig');
            const user = authPingooClinic.currentUser; // Get the current user
            if (!user) {
                throw new Error('User not authenticated');
            }
            const userId = user.uid; // Get the user ID
            const storage = getStorage(); // Get the storage instance
            const uniqueFileName = `recordings/${userId}/users/${selectedUser.uid}/files/${fileId}.wav`; // Save the file under a folder with the user ID
            const fileRef = ref(storage, uniqueFileName); // Create a reference to the file in storage
            const metadata = {
                customMetadata: {
                    user: selectedUser.uid
                }
            };

            const uploadTask = uploadBytesResumable(fileRef, blob, metadata); // Use uploadBytesResumable for progress tracking

            // Listen for state changes, errors, and completion
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Calculate and report upload progress
                    const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    onStatusUpdate(RecordingStatus.Uploading, fileId,progress);
                }, 
                (error) => {
                    console.error('Error uploading blob to Firebase:', error);
                    onStatusUpdate(RecordingStatus.UploadError,fileId);
                }, 
                () => {
                    onStatusUpdate(RecordingStatus.UploadComplete,fileId);
                    console.log('Blob uploaded successfully');
                }
            );
            await uploadBytes(fileRef, blob, metadata); // Upload the blob to storage with metadata
            console.log('Blob uploaded successfully');
        } catch (error) {
            onStatusUpdate(RecordingStatus.UploadError,fileId);
            console.error('Error uploading blob to Firebase:', error);
        }
    }

    return (

        <div className={`py-5 ${styles.container}`}>
            <div className={styles['recording-section']}>
                {selectedUser.uid && <>
                <div><strong>Start recording or upload a prerecorded file:</strong></div>
                    <div className="d-flex p-4 ">
                        <Button
                            variant={isRecording ? 'danger' : 'success'}
                            onClick={isRecording ? stopRecording : startRecording}
                            size="lg"
                            className={`text-white`}
                        >
                            <FontAwesomeIcon icon={isRecording ? faStopCircle : faMicrophone} />
                            {isRecording ? ' Stop Recording' : ' Start Recording'}
                        </Button>
                        {!(mediaRecorderRef.current && isRecording) && <>
                            <div className="my-auto text-center px-3">OR</div>
                            <UploadButton onFileSelected={uploadAudioFirebase}></UploadButton>
                        </>}
                        {mediaRecorderRef.current && isRecording && (
                            <Row className="ms-2">
                                <LiveAudioVisualizer
                                    mediaRecorder={mediaRecorderRef.current}
                                    fftSize={256}
                                    width={200}
                                    height={50}
                                    barColor={'green'}
                                />
                            </Row>
                        )}
                    </div>
                </>}
            </div>
        </div>
    );
};

export default RecordingComponent;

